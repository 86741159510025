import type { FC } from 'react';
import React from 'react';
import { AppBar, Button, Container, Grid, Toolbar } from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';
import { useRouter } from 'next/router';

interface MainNavbarProps {}

export const MainNavbar: FC<MainNavbarProps> = props => {
  const isMounted = useMounted();
  const router = useRouter();
  const { loginWithPopup, logout, isAuthenticated } = useAuth();

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithPopup();

      if (isMounted()) {
        const returnUrl = (router.query.returnUrl as string) || '/';

        router.push(returnUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'primary.main',
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: 'text.secondary',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          <Grid container justifyContent="end">
            <Grid item>
              {isAuthenticated && (
                <Button component="a" onClick={handleLogout} size="medium" variant="outlined">
                  Log out
                </Button>
              )}
              {!isAuthenticated && (
                <Button
                  id="login-button"
                  component="a"
                  onClick={handleLogin}
                  size="medium"
                  sx={{ borderColor: 'neutral.300', color: 'neutral.300' }}
                  variant="outlined"
                >
                  Log in
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

MainNavbar.propTypes = {};
