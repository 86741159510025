import type { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';

export const HomeHero: FC = props => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        pt: 6,
      }}
      {...props}
    >
      <Container
        maxWidth="md"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography align="center" variant="h1" mt={7}>
          SMART
        </Typography>
        <Typography align="center" variant="h1">
          Onderhoudsconfigurator
        </Typography>
        <Box
          sx={{
            alignItems: {
              sm: 'center',
              xs: 'flex-start',
            },
            display: 'flex',
            flexDirection: {
              sm: 'row',
              xs: 'column',
            },
            py: 3,
            m: -1,
            '& > *': {
              m: 1,
            },
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mx: -1,
            mt: 2,
            mb: 6,
            '& > a': {
              m: 1,
            },
          }}
        ></Box>
      </Container>
    </Box>
  );
};
