import type { FC } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const Footer: FC = props => (
  <Box
    component="footer"
    sx={{
      backgroundColor: 'primary.main',
      borderTopColor: 'divider',
      borderTopWidth: 1,
      pb: 3,
      pt: {
        md: 8,
        xs: 6,
      },
      position: 'absolute',
      height: '30%',
      width: '100%',
      bottom: 0,
    }}
    {...props}
  >
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={4.5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                component={'img'}
                alt={'logo'}
                src={'/static/SMART_Logo_DIAP.png'}
                sx={{
                  maxHeight: '100%',
                  width: '40%',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'start',
                }}
              >
                <Typography color={'#8faabe'} sx={{ mr: 1.5, fontSize: '13px', whiteSpace: 'nowrap' }}>
                  Powered by
                </Typography>
                <Box
                  component={'img'}
                  alt={'logo'}
                  src={'/static/rutges_logo_wit_vern.png'}
                  sx={{
                    maxHeight: '80%',
                    width: '15%',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: alpha('#ffffff', 0.12),
          my: 5,
        }}
      />
      <Typography color="textSecondary" variant="caption">
        All Rights Reserved.
      </Typography>
    </Container>
  </Box>
);
